.copyright{
    text-align: center;
}

.footerValues{
    text-align: center;
}

.socialIconFooter{
    margin-top: 20px !important;
}