@import "~react-image-gallery/styles/css/image-gallery.css";

.select-input {
    padding: 0px !important;
}

.activeLink{
    text-decoration: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    font-size: large;
}