.inicialLogo{
    width: 75px;
    height: 25px;
}

.menuBar{
    padding: 20px;
}

.menuLink{
    color: #FFF !important;
    background-color: transparent !important;
}