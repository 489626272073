.contactTitle{
    text-align: center;
}

.inputFormGroup{
    margin-top: 100px;
}

.contactForm{
    margin-bottom: 1rem;
}